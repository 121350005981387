<!--门店平台-->
<template>
    <div>
        <!--顶部-->
        <!-- <el-row type="flex" justify="space-between" align="middle" class="commonTop">
            <el-row type="flex" align="middle" class="name">
                <router-link v-if="userInfo.is_admin == 1" :to="{ name: '统计概况'}">织脉数字化商城连锁版后台管理系统</router-link>
                <router-link v-if="userInfo.is_admin == 3" :to="{ name: '门店统计概况'}">织脉数字化商城连锁版后台管理系统</router-link>
            </el-row>
            <el-row type="flex" justify="space-between" align="middle" class="nav">
                <el-row type="flex" align="middle" class="msg" >
                    <p>消息通知</p>
                    <el-badge :value="0" class="item">
                        <img src="../assets/laba2.png" alt="">
                    </el-badge>
                </el-row>

                <el-row class="user">
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link flex flex-ai-c">
                            {{userInfo.is_admin == 1 ? userInfo.user_name : userInfo.name}}
                            <i class="el-icon-arrow-down" style="margin-left:5px"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-switch-button" @click.native="logOut()">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-row>
            </el-row>
        </el-row> -->

        <!--左侧菜单-->
        <el-row class="commonLeft" :style="{'width':isCollapse?'64px':'200px'}">
            <el-row class="nav">
                <el-aside>
                    <el-row type="flex" justify="center" class="headImg">
                        <img src="../assets/avatars.png" :style="{'width':isCollapse?'42px':'60px'}"/>
                        <p v-if="!isCollapse">{{houseInfo.is_admin == 1 ? adminInfo.username : houseInfo.username}}</p>
                    </el-row>
                    <el-menu background-color="#001529" text-color="#EBF2FF" :default-active="$route.path" :unique-opened="true" active-text-color="#fff" :router="true" :collapse="isCollapse" style="height:calc( 100% - 184px )">
                        <template v-for="nav in navList2">
                            <el-menu-item :index="nav.path" :key="nav.name" _click="navTo(nav.path)" v-if="!nav.sub">
                                <i :class="nav.icon" v-if="nav.icon"></i>
                                <span slot="title">{{nav.name}}</span>
                            </el-menu-item>

                            <el-submenu :index="nav.path" :key="nav.name" v-if="nav.sub">
                                <template slot="title">
                                    <i :class="nav.icon" v-if="nav.icon"></i>
                                    <span>{{nav.name}}</span>
                                </template>
                                <template v-for="item in nav.sub">
                                    <el-menu-item :index="item.path" :key="item.name" v-if="!item.sub">
                                        <i :class="item.icon" v-if="item.icon"></i>
                                        {{item.name}}
                                    </el-menu-item>

                                    <el-submenu :index="item.path" :key="item.name" v-if="item.sub">
                                        <template slot="title">
                                            <i :class="item.icon" v-if="item.icon"></i>
                                            <span>{{item.name}}</span>
                                        </template>
                                        <el-menu-item v-for="item2 in item.sub" :index="item2.path" :key="item2.name">
                                            <i :class="item2.icon" v-if="item2.icon"></i>
                                            {{item2.name}}
                                        </el-menu-item>
                                    </el-submenu>
                                </template>
                            </el-submenu>
                        </template>
                    </el-menu>
                    <el-row class="menuBtnbox" :style="{'width':isCollapse?'64px':'200px'}">
                        <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                    </el-row>
                </el-aside>
            </el-row>
        </el-row>

        <!--主要内容-->
        <el-row class="commonRight" :style="{'left':isCollapse?'64px':'200px'}">
            <router-view :isCollapse="isCollapse" />
        </el-row>
    </div>
</template>

<script>
    import {enterHouseApi}  from '@/api/house.js';
    export default {
        data: function() {
            return {
                defaultActive: this.$route.path,
                isCollapse: false,
                transitionName: '',
                adminInfo: {},
                houseInfo:{},
                navList2: [{
                    name: '统计概况',
                    path: '/houseOverview',
                    icon: 'el-icon-s-home'
                },{
					name: '商品管理',
					path: '/sale',
					icon: 'el-icon-s-data',
					sub: [{
						name: '商品入库',
						path: '/enterHouseAdd',
					}, {
						name: '入库列表',
						path: '/enterHouse',
					}]
				},
				// {
    //                 name: '商品入库',
    //                 path: '/enterHouse',
    //                 icon: 'el-icon-sold-out',
					
    //             },
				{
                    name: '线下出库',
                    path: '/outHouse',
                    icon: 'el-icon-sell'
                },
                {
                    name: '办会员卡',
                    path: '/memberHouse',
                    icon: 'el-icon-postcard'
                },
                {
                    name: '商品订单',
                    path: '/orderHouse',
                    icon: 'el-icon-s-order'
                },{
                    name: '会员管理',
                    path: '/houseMember',
                    icon: 'el-icon-bank-card'
                },{
                    name: '库存预警',
                    path: '/stockWarning',
                    icon: 'el-icon-odometer'
                },{
                    name: '账号管理',
                    path: '/adminPage',
                    icon: 'el-icon-s-tools'
                }]
            }
        },
         //使用watch 监听$router的变化
        watch: { 
            $route(to, from) {
                //如果to索引大于from索引,判断为前进状态,反之则为后退状态
                if (to.meta.index > from.meta.index) {
                    //设置动画名称
                    this.transitionName = 'slide-left';
                } else {
                    this.transitionName = 'slide-right';
                }
            }
        },
        mounted: function() {
            
            this.houseInfo = localStorage.getItem('houseInfo') ? JSON.parse(localStorage.getItem('houseInfo')) : this.houseInfo;
            this.adminInfo = localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')) : this.adminInfo;
            // if(this.houseInfo){
            //     this.enterHouse();
            // }
        },
        methods: {
            enterHouse:function(){
                enterHouseApi({
                    id:this.houseInfo.is_admin == 3 ? this.houseInfo.warehouse_id  :  this.houseInfo.id
                })
            },
            //nav跳转
            navTo: function(path) {
                this.$router.push(path);
            },  
            handleCollapse: function() {
                this.isCollapse = !this.isCollapse;
            },
            
            // 退出登录
            logOut: function() {
                var api = "/login/logout";
                this.http.post(api).then(() => {
                    localStorage.clear();
                    this.$router.push("/");
                });
            }
        }
    }
</script>
<style media="screen">
    .slide-right-enter-active,
    .slide-right-leave-active,
    .slide-left-enter-active,
    .slide-left-leave-active {
        will-change: transform;
        transition: all 500ms;
        position: absolute;
    }

    .slide-right-enter {
        opacity: 0;
        transform: translate3d(-100%, 0, 200);
    }

    .slide-right-leave-active {
        opacity: 0;
        transform: translate3d(100%, 0, 200);
    }
</style>
